import axios from "./_base";
import errorHandler from "./_errorHandler";

export const getData = (vue, search, callback) => {

    if (vue.$store.state.workerIsOffline && vue._.size(vue.$store.state.workerOfflineData) !== 0) {
        callback(vue.$store.state.workerOfflineData);
    } else {
        search = search.trim();
        let url = `http://192.168.43.96/api/worker_dashboard_customers`;
        if (search !== '') {
            url = `${url}?search=${search}`
        }

        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${vue.$store.state.user.token}`,
            },
        }).then((response) => {
            vue.$store.commit('setWorkerOfflineData', response.data);
            callback(response.data);
        }).catch((error) => {
            errorHandler(vue, error)
        });
    }
};

export const setData = (vue) => {

    let url = `http://192.168.43.96/api/worker_dashboard_customers`;

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        vue.$store.commit('setWorkerOfflineData', response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};

export const insertInvoice = (vue, invoice, callback, errorCallback) => {
    axios.post(`http://192.168.43.96/api/worker_dashboard_insert_invoice`, invoice, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};
