<template>
  <div class="c-app wy-dashboard-layout">
    <invoice-loader :enabled="invoiceLoaderIsEnabled" :number="numberOfOfflineInvoices"/>
    <CModal
        color="red"
        centered
        :show.sync="$store.state.confirmationModalIsOpen"
        :closeOnBackdrop="false"
    >
      {{ $store.state.confirmationModalMessage }}
      <template #header-wrapper>&nbsp;</template>
      <template #footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="$store.commit('closeConfirmationModal')"
        >
          {{ $t('no') }}
        </button>
        <button
            type="button"
            class="btn btn-red"
            @click="$store.state.confirmationModalOnOk(); $store.commit('closeConfirmationModal');"
        >
          {{ $t('yes') }}
        </button>
      </template>
    </CModal>

<!--    <TheWorkerSidebar/>-->
    <CWrapper>
<!--      <TheWorkerHeader/>-->
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <div class="mt-4 mb-2 text-center">&copy; {{new Date().getFullYear()}} {{ $t(`${$config.profile.name}.footerText`) }}</div>
      <TheWorkerFooter/>
    </CWrapper>
  </div>
</template>

<script>
// import TheWorkerSidebar from './TheWorkerSidebar'
// import TheWorkerHeader from './TheWorkerHeader'
import TheWorkerFooter from './TheWorkerFooter'
import {insertInvoice} from "../api/workerDashboard";
import InvoiceLoader from "../views/components/InvoiceLoader";

export default {
  name: 'TheContainer',
  components: {
    // TheWorkerSidebar,
    // TheWorkerHeader,
    TheWorkerFooter,
    InvoiceLoader,
  },
  data() {
    return {
      authorizationModalIsOpen: false,
      invoiceLoaderIsEnabled: false,
    };
  },
  watch: {
    '$store.state.globalAuthorizationError': function (globalAuthorizationError) {
      this.authorizationModalIsOpen = !this._.isEmpty(globalAuthorizationError);
    },
    '$store.state.workerIsOffline': function (workerIsOffline) {
      if (!workerIsOffline && this._.size(this.$store.state.workerOfflineInvoices) > 0) {
        this.sendOfflineInvoice();
      }
    },
  },
  computed: {
    numberOfOfflineInvoices() {
      return this._.size(this.$store.state.workerOfflineInvoices);
    },
  },
  methods: {
    closeAuthorizationModal() {
      // this.$router.push({ name: 'UserHome' });
      this.$logout(this);
      this.$store.commit('clearGlobalAuthorizationError');
    },
    sendOfflineInvoice() {
      this.$store.commit('pauseGlobalLoading');
      this.invoiceLoaderIsEnabled = true;
      setTimeout(() => {
        insertInvoice(this, this.$store.state.workerOfflineInvoices[0], () => {
          this.$store.commit('removeFirstWorkerOfflineInvoice');
          setTimeout(() => {
            if (this._.size(this.$store.state.workerOfflineInvoices) > 0) {
              this.sendOfflineInvoice();
            } else {
              this.$store.commit('resumeGlobalLoading');
              this.invoiceLoaderIsEnabled = false;
            }
          }, 50);
        });
      }, 50);
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
