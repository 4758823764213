<template>
  <CFooter :fixed="true" style="background-color: #FFFFFF;">
    <ul class="wy-footer-ul">
      <TheWorkerHeaderDropdownAccount/>
    </ul>
    <div>
      <span class="ml-1"></span>
    </div>
    <div class="mfs-auto">
      <div class="d-flex justify-content-start">
        <div>
          <div class="ml-2 mr-2">
            <span class="badge badge-primary badge-pill">{{numberOfOfflineInvoices}}</span>
          </div>
        </div>
        <div class="pl-1">
          <span v-if="workerIsOnline">{{ $t('online') }}</span>
          <span v-else>{{ $t('offline') }}</span>
        </div>
        <div>
          <CSwitch class="mx-1" color="success" :checked="workerIsOnline" @update:checked="onChange"  />
        </div>
      </div>
    </div>
  </CFooter>
</template>

<script>
import TheWorkerHeaderDropdownAccount from "./TheWorkerHeaderDropdownAccount";

export default {
  name: 'TheFooter',
  components: {
    TheWorkerHeaderDropdownAccount,
  },
  data() {
    return {
      workerIsOnline: true,
    }
  },
  computed: {
    numberOfOfflineInvoices() {
      return this._.size(this.$store.state.workerOfflineInvoices);
    },
  },
  watch: {
    workerIsOnline(newValue) {
      if (newValue) {
        this.$store.commit('setWorkerIsOnline');
      } else {
        this.$store.commit('setWorkerIsOffline');
      }
    }
  },
  mounted() {
    this.workerIsOnline = !this.$store.state.workerIsOffline;
  },
  methods: {
    onChange(value) {
      this.workerIsOnline = value;
    }
  }
}
</script>

<style lang="scss">
.wy-footer-ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 5px;
}
</style>
