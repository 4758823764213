<template>
  <div class="invoice-loader-background" v-if="enabled">
    <span class="invoice-loader-helper"></span>
    <div class="invoice-loader">
      <img src="~@/assets/images/invoice-bill-svgrepo-com.svg" alt="">
      <div>
        {{ number }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceLoader',
  props: {
    action: Function,
    enabled: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
    },
  },
  methods: {
    start() {
      this.enabled = true;
    },
    stop() {
      this.enabled = false;
    },
  },
};
</script>

<style lang="scss">
@keyframes stretch {
  0% {
    transform: scale(.4);
    //transform: scale(.4) rotate(5deg);
  }

  50% {
  }

  100% {
    // transform: rotate(-5deg);
  }
}
@keyframes stretch-2 {
  0% {

  }

  50% {

  }

  100% {
    transform: scale(.4);
  }
}

.invoice-loader-background {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //background-color: rgba(255,255,255,0.7);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  text-align: center;

  .invoice-loader {
    display: inline;
    vertical-align: middle;
    height: 250px;
    color: #FFFFFF;
    font-size: 60px;
    margin: 0 auto;
    text-align: center;
    animation-name: stretch;
    animation-duration: 800ms;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    img {
      width: 100px;
    }

    &.invoice-loader-2 {
      animation-name: stretch-2;
    }
  }

  .invoice-loader-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
</style>
